import consumer from "./consumer"
document.addEventListener("turbo:load", function() {
  if ($("meta[name='current-user']").length > 0) {
    consumer.subscriptions.create("CmdrpositionChannel", {
      connected() {
        console.log("connected to cmdr position channel")
        // Called when the subscription is ready for use on the server
      },
    
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
    
      received(data) {
        // Called when there's incoming data on the websocket for this channel
        var check = $('#cmdr-position-data').length;
        if (check >0){
        // console.log(data.cmdr);
          let html;
          let cmdr;
          html = data.html;
          cmdr = data.cmdr;
          var old = $("#"+cmdr).length;
          var paras = document.getElementsByClassName(cmdr);
    
          while(paras[0]) {
          paras[0].parentNode.removeChild(paras[0]);
          }
          // console.log(old);
          $(html).prependTo("table > tbody");
          var num = document.getElementsByClassName("num");
          $(num).each(function(i){
            $(this).text(i+1);
          });
    
          // const messageContainer = document.getElementById('messages');
          // messageContainer.innerHTML = messageContainer.innerHTML + html;
        };
        }
    });
  };
});
