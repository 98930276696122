import consumer from "./consumer"
document.addEventListener("turbo:load", function() {
  if ($("meta[name='hotmess-tag']").length > 0) {
    consumer.subscriptions.create("HotmessChannel", {
      connected() {
        console.log("connected to hotmess delivery alerts channel")
        // Called when the subscription is ready for use on the server
      },
    
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
    
      received(data) {
        // Called when there's incoming data on the websocket for this channel

        var cmdr_squadron = document.querySelector("meta[name='hotmess-tag']").getAttribute("info");
        if (cmdr_squadron.length > 0) {
          let type = data.type;
          let html = data.html;
          let system_id = data.system_id;
          let market_id = data.market_id;
          if (type == 1) {
            // Check that the page contains the system we are looking for
            let systemTbody = document.getElementById(system_id);
            if (systemTbody == null) {
              // if this is null the system does not exist on the page
            } else {
              let stationRow = document.getElementById(market_id);
              if (stationRow == null){
                // update the table if the StationRow does not exist
                var newRow = systemTbody.insertRow(systemTbody.rows.length);
                newRow.id = market_id;
                newRow.innerHTML = html;
              } else {
                if (stationRow.innerHTML.length > 0){
                  $(stationRow).replaceWith(html).html;
                };
              };
            };
          };
          if (type == 2){
            let info_table = document.getElementById('hotmess-messages');
            $(html).prependTo(info_table).html;
            var num = document.getElementsByClassName("num");
            $(num).each(function(i){
              $(this).text(i+1);
              if(i > 9) {
                info_table.deleteRow(i);
              };
            });
          };
        };
        }
    });
  };
});