import consumer from "./consumer"
document.addEventListener("turbo:load", function() {
  if ($("meta[name='dj-tag']").length > 0) {
    consumer.subscriptions.create("DjChannel", {
      connected() {
        console.log("connected to DJ channel")
        // Called when the subscription is ready for use on the server
      },
    
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
      
      received(data) {
        // Called when there's incoming data on the websocket for this channel
        
        var does_tag_exist = document.querySelector("meta[name='dj-tag']").getAttribute("info");
        if (does_tag_exist.length > 0) {
          let uuid = data.uuid;
          let type = data.type;
          let timestamp = data.timestamp;
          let request = data.request;
          let commandername = data.commandername;
          let link = data.link;
          let shoutout = data.shoutout;
          if (type == "request"){
            console.log("request incoming");
            let tbody = document.getElementById('request-tbody')
            let check_for_id = document.getElementById(uuid);
            if (check_for_id == null){
              
              let newRow = tbody.insertRow(-1);
              newRow.id = uuid;
              let c0 = newRow.insertCell(0);
              let c1 = newRow.insertCell(1);
              let c2 = newRow.insertCell(2);
              let c3 = newRow.insertCell(3);
              c0.innerHTML = request;
              c1.innerHTML = commandername;
              c2.innerHTML = timestamp;
              c3.innerHTML = link
            } else {
              
            }
          };
          if (type == "request_delete"){
            let row = document.getElementById(uuid);
            if (row.attributes.length > 0){
              row.remove()
            } else {
  
            };
          }
          if (type == "clear_shoutouts"){
            let shoutout_tbody = document.getElementById('shoutout-tbody');
            shoutout_tbody.remove();
          }
          if (type == "clear_requests"){
            let request_tbody = document.getElementById('request-tbody');
            request_tbody.remove();
          }
          if (type == "shoutout"){
            
            let shoutout_tbody = document.getElementById('shoutout-tbody')
            let check_for_shoutout_id = document.getElementById(uuid);
            if (check_for_shoutout_id == null){
              
              let snewRow = shoutout_tbody.insertRow(-1);
              snewRow.id = uuid;
              let sc0 = snewRow.insertCell(0);
              let sc1 = snewRow.insertCell(1);
              let sc2 = snewRow.insertCell(2);
              let sc3 = snewRow.insertCell(3);
              sc0.innerHTML = shoutout;
              sc1.innerHTML = commandername;
              sc2.innerHTML = timestamp;
              sc3.innerHTML = link
            } else {
              
            }
          };
          if (type == "shoutout_delete"){
            let srow = document.getElementById(uuid);
            if (srow.attributes.length > 0){
              srow.remove()
            } else {
  
            };
          }
        };
        }
    });
  };
});