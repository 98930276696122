import consumer from "./consumer"
document.addEventListener("turbo:load", function() {
  if ($("meta[name='faction-tag']").length > 0) {
    consumer.subscriptions.create("BountyalertChannel", {
      connected() {
        console.log("connected to bounty alerts channel")
        // Called when the subscription is ready for use on the server
      },
    
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
    
      received(data) {
        // Called when there's incoming data on the websocket for this channel

        var bounty_type = document.querySelector("meta[name='faction-tag']").getAttribute("info");
        if (bounty_type.length > 0) {
          var check = $('#bounty-alert-data').length;
          if (check >0){
            let html;
            let cmdr;
            let faction
            // let table = document.querySelector('table');
            let table = document.getElementById('bounty-alert-data');
            html = data.html;
            cmdr = data.cmdr;
            faction = data.faction;
            if (faction == bounty_type){
              $(html).prependTo(table).html;
              var num = document.getElementsByClassName("num");
              $(num).each(function(i){
                // $(this).text(i+1);
                if(i > 14) {
                  table.deleteRow(i);
                }
              });
            }
          };
        };
        }
    });
  };
});
