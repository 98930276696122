// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("jquery");
// require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("chart.js");
import "@fortawesome/fontawesome-free/js/all";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
require("@hotwired/turbo")
window.tippy = tippy;
FontAwesome.config.mutateApproach = 'sync'
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("trix")
require("@rails/actiontext")

window.addEventListener("trix-file-accept", function(event) {
    event.preventDefault()
    alert("File attachment not supported!")
  })
  $(document).on("turbo:load", () => {
    // console.log("turbo!")
  })
  