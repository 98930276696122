import consumer from "./consumer"
document.addEventListener("turbo:load", function() {
  if ($("meta[name='gold-tag']").length > 0) {
    consumer.subscriptions.create("GoingForGoldChannel", {
      connected() {
        console.log("connected to going for gold scan alerts channel")
        // Called when the subscription is ready for use on the server
      },
    
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
    
      received(data) {
        // Called when there's incoming data on the websocket for this channel

        var cmdr_squadron = document.querySelector("meta[name='gold-tag']").getAttribute("info");
        if (cmdr_squadron.length > 0) {
          var check = $('#scan-alert-data').length;
          if (check >0){
            let html;
            let cmdr;
            let faction
            // let table = document.querySelector('table');
            let table = document.getElementById('scan-alert-data');
            html = data.html;
            cmdr = data.cmdr;
            faction = data.faction;
            if (faction == cmdr_squadron){
              $(html).prependTo(table).html;
              var num = document.getElementsByClassName("num");
              $(num).each(function(i){
                // $(this).text(i+1);
                if(i > 20) {
                  table.deleteRow(i);
                }
              });
            }
          };
        };
        }
    });
  };
});
