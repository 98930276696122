import consumer from "./consumer"
document.addEventListener("turbo:load", function() {
  if ($("meta[name='hm2-tag']").length > 0) {
    consumer.subscriptions.create("Hm2Channel", {
      connected() {
        console.log("connected to hotmess 2 simplified channel")
        // Called when the subscription is ready for use on the server
      },
    
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
      
      received(data) {
        // Called when there's incoming data on the websocket for this channel
        
        var cmdr = document.querySelector("meta[name='hm2-tag']").getAttribute("info");
        if (cmdr.length > 0) {
          let market_id = data.market_id;
          let html = data.html;
          let type = data.type;
          let system_id = data.system_id;
          let cmdr_id = data.cmdr_id;
          if (type == 1) {
            // Check that the page contains the system we are looking for
            let systemTbody = document.getElementById(system_id);
            if (systemTbody == null) {
              // if this is null the system does not exist on the page
              } else {
                let stationRow = document.getElementById(market_id);
                if (stationRow == null){
                  // update the table if the StationRow does not exist
                  var newRow = systemTbody.insertRow(systemTbody.rows.length);
                  newRow.id = market_id
                  newRow.innerHTML = html
                  console.log(html);
                } else {
                  if (stationRow.innerHTML.length > 0){
                    console.log("station was replaced");
                    $(stationRow).replaceWith(html).html;
                  };
                };
              };
            };
            if (cmdr == cmdr_id){
              if (type == 232){
                setTimeout(location.reload(), 3000);
              }
            };
        };
        }
    });
  };
});